import React from 'react';
import s from './MyPopupError.module.css'
import {withTranslation} from "react-i18next";
import {t} from "i18next";

const MyPopupError = ({visible, setVisible, error}) => {

    const rootClasses = [s.myModal]

    if (visible) {
        rootClasses.push(s.active)
    }

    const getErrorMessage = () => {
        if (error?.status === 403) {
            return error.data.message
        }
        return t('popup.wrong')
    }

    return (
        <div className={rootClasses.join(' ')} onClick={() => setVisible(false)}>
            <div className={s.myModalContent} onClick={(e) => e.stopPropagation()}>
                <div className={s.popup_title}>
                    {t('popup.oops')}
                </div>

                <div className={s.popup_subtitle}>
                    {getErrorMessage()}
                </div>

                <div className={s.flex}>
                    <button className={s.my_btn} onClick={() => setVisible(false)}>{t('popup.ok')}</button>
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(MyPopupError)
