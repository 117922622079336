import React, {useState} from "react";
import Account from "../../Account";
import "./Exchange.module.css";
import s from "./Exchange.module.css";
import revert from '../../../../assets/newImg/account/revert.png'
import {useSelector} from "react-redux";
import {AuthAPI} from "../../../Api/Api";
import MyPopupSuccess from "../../../../utils/MyPopupSuccess/MyPopupSuccess";
import {t} from "i18next";
import {withTranslation} from "react-i18next";
import MyPopupError from "../../../../utils/MyPopupError/MyPopupError";

const Exchange = () => {

    const [exchangeData, setExchangeData] = useState({
        amount: '',
        from_account: '',
        to_account: '',
        reference: ''
    })
    const [displayedAccount, setDisplayedAccount] = useState('Select Account')
    const [displayedAccount2, setDisplayedAccount2] = useState('Select Account')
    const [popupVisible, setPopupVisible] = useState(false)
    const [popupErrorVisible, setPopupErrorVisible] = useState(false)
    const [error, setError] = useState(null)


    const state = useSelector((state) => state);
    const {UserReducer: user} = state;


    const accounts = user?.data?.accounts

    const onSelectAccount = (e) => {
        setDisplayedAccount(e.target.value)
        setExchangeData({...exchangeData, from_account: e.target.value})
    }

    const onSelectAccount2 = (e) => {
        setDisplayedAccount2(e.target.value)
        setExchangeData({...exchangeData, to_account: e.target.value})
    }

    const handleRevert = () => {
        const firstSelect = displayedAccount
        setDisplayedAccount(displayedAccount2)
        setDisplayedAccount2(firstSelect)
        setExchangeData({...exchangeData, to_account: firstSelect, from_account: displayedAccount2})
    }

    const sendExchangeTransfer = () => {
        const token = localStorage.getItem('token')
        AuthAPI.sendExchangeTransfer(token, exchangeData)
            .then(() => {
                setPopupVisible(true)
            }).catch((e) => {
                setPopupErrorVisible(true)
                setError(e.response)
        })
    }

    const getTermsAndConditionsLink = () => {
        if (localStorage.getItem('lang') === 'en') {
            return "/TURKISH_INTERNATIONAL_INVESTMENT_BANK_LIMITED_TERMS_AND_CONDITIONS.pdf"
        } else if (localStorage.getItem('lang') === 'tr') {
            return "/TURKIYE_UYB_SARTLAR_VE_KOSULLAR.pdf"
        } else {
            return "/Условия_и_Положения.pdf"
        }
    }

    return (
        <Account
            activeTab="exchange-transfer"
            title={t('personal.exchange_and_internal_page.exchange_title')}
        >
            <MyPopupSuccess visible={popupVisible} setVisible={setPopupVisible}/>
            <MyPopupError visible={popupErrorVisible} setVisible={setPopupErrorVisible} error={error}/>
            <div className={s.row}>
                <div className={s.fields}>
                    <div>
                        <div className={s.spanBlock}>
                            <span>{t('personal.exchange_and_internal_page.from_acc')}</span>
                        </div>
                        <div>
                            <select className={s.my_select} value={displayedAccount} onChange={onSelectAccount}>
                                <option value='Select Account' disabled>
                                    <span>{t('personal.exchange_and_internal_page.select')}</span>
                                </option>
                                {accounts.map((acc) => {
                                    return (
                                        <option key={acc.account_special_number}
                                                value={acc.account_special_number}
                                                style={{height: '38px'}}
                                        >
                                            <span>{acc.account_special_number}</span>
                                            <span>{acc.currency_abbreviation}</span>
                                        </option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                </div>

                <div className={s.revert}>
                    <img src={revert} alt="" onClick={handleRevert}/>
                </div>

                <div className={s.fields}>
                    <div>
                        <div className={s.spanBlock}>
                            <span>{t('personal.exchange_and_internal_page.to_acc')}</span>
                        </div>
                        <div>
                            <select className={s.my_select} value={displayedAccount2} onChange={onSelectAccount2}>
                                <option value='Select Account' disabled>
                                    <span>{t('personal.exchange_and_internal_page.select')}</span>
                                </option>
                                {accounts.map((acc) => {
                                    return (
                                        <option key={acc.account_special_number}
                                                value={acc.account_special_number}
                                                style={{height: '38px'}}
                                        >
                                            <span>{acc.account_special_number}</span>
                                            <span>{acc.currency_abbreviation}</span>
                                        </option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <div className={s.row2}>
                <div className={s.fields}>
                    <div>
                        <div className={s.spanBlock}>
                            <span>{t('personal.exchange_and_internal_page.amount')}</span>
                        </div>
                        <div>
                            <input
                                className={s.fields__input}
                                placeholder={t('personal.exchange_and_internal_page.amount')}
                                value={exchangeData.amount}
                                onChange={(e) => {
                                    setExchangeData({...exchangeData, amount: e.currentTarget.value})
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className={s.fields}>
                    <div>
                        <div className={s.spanBlock}>
                            <span>{t('personal.exchange_and_internal_page.payment_ref')}</span>
                        </div>
                        <div>
                            <input
                                className={s.fields__input}
                                placeholder={t('personal.exchange_and_internal_page.payment_ref')}
                                value={exchangeData.reference}
                                onChange={(e) => {
                                    setExchangeData({...exchangeData, reference: e.currentTarget.value})
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div className={s.text_center}>
                    <input type="checkbox" className={s.checkbox}/>
                    <span
                        className={s.agree}>{t('personal.swift_page.i_agree_en')} {t('personal.swift_page.i_agree_tu_p1')} {t('personal.swift_page.i_agree_ru_p1')}</span>
                    <a
                        className={s.terms}
                        type="text"
                        target="_blank"
                        href={getTermsAndConditionsLink()}
                    >
                                <span
                                    className={s.terms}>{t('personal.swift_page.terms_tu')} {t('personal.swift_page.terms_en')} {t('personal.swift_page.terms_ru')}</span>
                    </a>
                    <span
                        className={s.agree}> {t('personal.swift_page.i_agree_tu_p2')} {t('personal.swift_page.i_agree_ru_p2')}</span>
                </div>
                <div>
                    <button
                        className={s.my_btn}
                        onClick={sendExchangeTransfer}
                    >{t('personal.transfer')}
                    </button>
                </div>
            </div>

        </Account>
    );
};

export default withTranslation()(Exchange)
